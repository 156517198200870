const links = document.querySelectorAll("a");
links.forEach(link => {
  if (
    !link.href.startsWith("/") &&
    !link.href.startsWith(window.location.origin) &&
    !link.href.startsWith("tel") &&
    !link.href.startsWith("mailto") &&
    !link.href.startsWith("https://s3.amazonaws.com") &&
    !link.href.startsWith("https://static.aldersprings.org")
  ) {
    link.target = "_blank";

    if (link.classList.contains("external-ignore")) return;

    const icon = document.createElement("i");
    icon.classList.add("fas");
    icon.classList.add("fa-external-link-alt");
    icon.style.marginLeft = "5px";
    link.appendChild(icon);

    const srNotice = document.createElement("span");
    srNotice.classList.add("sr-only");
    srNotice.textContent = "(opens in new tab)";
    link.appendChild(srNotice);
  }
});
