// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faSort,
  faBars,
  faTools,
  faUser,
  faAmericanSignLanguageInterpreting,
  faQuoteLeft,
  faFileInvoiceDollar,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(function() {
  library.add(
    faSearch,
    faTimes,
    faAngleRight,
    faPhoneAlt,
    faAngleLeft,
    faMapMarkerAlt,
    faEnvelope,
    faFacebookF,
    faPinterestP,
    faInstagram,
    faYoutube,
    faTwitter,
    faLinkedinIn,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faCalendarAlt,
    faClock,
    faSort,
    faBars,
    faTools,
    faUser,
    faAmericanSignLanguageInterpreting,
    faQuoteLeft,
    faFileInvoiceDollar,
    faExternalLinkAlt
  );

  dom.i2svg();
});
